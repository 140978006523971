import { validateWorkspaceName } from '../../../shared/validation'

export const navigateAuthenticatedUserToSubdomain = (subdomain = ''): void => {
  const protocol = window.location.protocol
  const newUrl = `${protocol}//${subdomain}.${process.env.NEXT_PUBLIC_APP_DOMAIN}`
  window.location.href = newUrl
}

/**
 * Checks whether a domain is valid
 */
export const testDomainIsValid = (domainName: string): boolean => {
  return validateWorkspaceName(domainName)
}

export type PublicationRouteParams = { params: { siteId: string; brand: string; workspace } }
export type WorkspaceRouteParams = { params: { workspace: string } }

export const buildAuthenticatedRoute = ({
  workspace,
  brand,
  siteId,
  pageSlug,
  sectionPageId,
  tagPageId,
  writerPageId,
}: {
  workspace: string
  brand?: string
  siteId?: string
  pageSlug?: string
  storyPageId?: string
  sectionPageId?: string
  tagPageId?: string
  writerPageId?: string
}): string => {
  if (workspace && brand && siteId && sectionPageId) {
    return `/${workspace}/${encodeURI(brand)}/${siteId}/sections/${sectionPageId}`
  } else if (workspace && brand && siteId && tagPageId) {
    return `/${workspace}/${encodeURI(brand)}/${siteId}/tags/${tagPageId}`
  } else if (workspace && brand && siteId && writerPageId) {
    return `/${workspace}/${encodeURI(brand)}/${siteId}/writers/${writerPageId}`
  } else if (workspace && brand && siteId && pageSlug) {
    return `/${workspace}/${encodeURI(brand)}/${siteId}${pageSlug}`
  } else if (workspace && brand && siteId) {
    return `/${workspace}/${encodeURI(brand)}/${siteId}`
  } else if (workspace && brand) {
    return `/${workspace}/${encodeURI(brand)}`
  } else if (workspace && pageSlug) {
    return `/${workspace}${pageSlug}`
  }
  return `/${workspace}`
}
