import type * as Stitches from '@stitches/react'
import React, { useCallback, useEffect, useState } from 'react'

import { CSS, styled } from '../stitches.config'
import { sharedTypography, sharedTypographyProps } from '../utils/stitchesShared-utils'
import { Box } from './Box'
import { Flex } from './Flex'
import { Icon } from './Icon'
import { Text } from './Text'

const DISABLED_DEFAULT = false

export type TextFieldProps = {
  /**
   *  Check to align text in center.
   */
  centerText?: boolean

  characterCountIsVisible?: boolean

  characterCountLimit?: number

  /**
   *  Check to auto complete the text field.
   */
  autocomplete?: 'off' | 'on'

  /**
   *  Check to auto grow the text field vertically.
   */
  autoGrow?: boolean

  /**
   *  Give css property to element.
   */
  css?: CSS

  /**
   *  Give caption to textfield.
   */
  caption?: string

  /**
   * Full width of its parent element.
   */
  fullWidth?: boolean

  /**
   *  Set to true to disable component.
   * Defaults to false
   */
  disabled?: boolean

  /**
   *  Set error text of textfield.
   */
  errorMsg?: string

  /**
   *  Set warning text of textfield.
   */
  warningMsg?: string

  /**
   *  Set the label of text field.
   */
  floatingLabel?: string

  /**
   *  Use material icon inside text field.
   */
  leftIconName?: string

  /**
   * Right icon to check the input field.
   */
  rightIconName?: string

  /**
   * Name of the textfield.
   */
  name: string

  maxDate?: string

  /**
   *  Callback function to interact with textfield.
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void

  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void

  /**
   * On Blur callbak.
   */
  onBlur?: (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void
  /**
   *  On Focus callback.
   */
  onFocus?: (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => void

  /**
   *  Set the placeholder text.
   */
  placeholder?: string

  /**
   *  Set the number of rows in textarea.
   */
  rows?: number

  /**
   *  Get the value of text field.
   */
  value?: string | number | null

  /**
   *  Check whether to use as password or usual input.
   */
  type?: string

  /**
   *  Set the font size of inputting text.
   */
  inputVariant?: sharedTypographyProps['variant']

  /**
   *  Set the font size of caption.
   */
  captionVariant?: sharedTypographyProps['variant']

  /**
   *  Set font size of error message.
   */
  errorMsgVariant?: sharedTypographyProps['variant']

  /**
   *  Set font size of floating label.
   */
  floatingLabelVariant?: sharedTypographyProps['variant']

  /**
   * Set autofocus on textfield.
   * Defaults to false
   */
  autoFocus?: boolean

  /**
   * Set read only on textfield.
   */
  readOnly?: boolean

  /**
   * Set input props on textfield.
   *
   */
  inputContainerVariant?: Stitches.VariantProps<typeof SingleLineTextfieldContainer>

  inputExtraOptions?: React.InputHTMLAttributes<HTMLInputElement>

  reactFormErrors?: unknown // TODO: replace any by something with FieldErrors like FieldErrors<Record<string, any>>

  min?: string
  max?: string
}

export const Textfield = React.forwardRef<HTMLDivElement, TextFieldProps>((props, forwardRef) => {
  const {
    autocomplete,
    disabled = DISABLED_DEFAULT,
    placeholder,
    value = '',
    type = 'text',
    css,
    centerText = false,
    fullWidth = FULL_WIDTH_DEFAULT,
    leftIconName,
    rightIconName,
    onChange,
    onBlur,
    onFocus,
    caption,
    errorMsg,
    warningMsg,
    floatingLabel,
    name,
    rows,
    inputVariant,
    captionVariant,
    floatingLabelVariant,
    errorMsgVariant,
    autoFocus = false,
    readOnly,
    inputContainerVariant,
    inputExtraOptions,
    reactFormErrors,
    onKeyDown,
    autoGrow = false,
    characterCountIsVisible = false,
    characterCountLimit,
    maxDate,
  } = props
  const [isInputFocused, setIsInputFocused] = useState(autoFocus)
  const [revealPassword, setRevealPassword] = useState(false)
  const textfieldValue = value || ''
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const handleAutoGrow = useCallback(
    e => {
      if (autoGrow) {
        e.currentTarget.style.height = 'inherit'
        e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
      }
    },
    [autoGrow],
  )

  useEffect(() => {
    if (autoGrow && textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [autoGrow])

  return (
    <TextfieldWrapper direction="column" isFullWidth={fullWidth} css={css} ref={forwardRef}>
      {(floatingLabel || characterCountIsVisible) && (
        <Flex gap={2}>
          {floatingLabel && (
            <FloatingLabelText isDisabled={disabled} variant={floatingLabelVariant ? floatingLabelVariant : 'overline'}>
              {floatingLabel}
            </FloatingLabelText>
          )}
          {characterCountIsVisible && (
            <CharacterCountText
              isOverLimit={characterCountLimit ? textfieldValue.toString()?.length > characterCountLimit : false}
              variant={floatingLabelVariant ? floatingLabelVariant : 'overline'}>
              {textfieldValue.toString().length}/{characterCountLimit}
            </CharacterCountText>
          )}
        </Flex>
      )}
      {rows ? (
        <Flex css={{ borderBottom: '1px solid $gs6', borderRadius: '$3' }}>
          <TextArea
            ref={textareaRef}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            rows={rows}
            value={textfieldValue}
            variant={inputVariant}
            onBlur={onBlur}
            autoComplete={autocomplete}
            readOnly={readOnly}
            disabled={disabled}
            onFocus={onFocus}
            onKeyUp={handleAutoGrow}
            onKeyDown={onKeyDown}
          />
        </Flex>
      ) : (
        <SingleLineTextfieldContainer
          {...inputContainerVariant}
          centerText={centerText}
          bottomRadius={errorMsg || warningMsg ? 'straight' : 'curved'}
          align="center"
          borderColor={isInputFocused ? 'pronounced' : 'slight'}>
          {leftIconName && (
            <Box css={{ cursor: 'pointer', pl: '$4' }}>
              <Icon name={leftIconName} type="outlined" css={{ color: disabled ? '$gs8' : '$gs11' }} />
            </Box>
          )}
          <Input
            value={textfieldValue}
            type={type !== 'password' ? type : revealPassword ? 'text' : 'password'}
            placeholder={placeholder}
            onChange={onChange}
            name={name}
            variant={inputVariant}
            onBlur={e => {
              setIsInputFocused(false)
              if (onBlur) {
                onBlur(e)
              }
            }}
            onFocus={e => {
              setIsInputFocused(true)
              if (onFocus) onFocus(e)
            }}
            onKeyDown={onKeyDown}
            autoComplete={autocomplete}
            disabled={disabled}
            autoFocus={autoFocus}
            readOnly={readOnly}
            max={maxDate}
            {...inputExtraOptions}
          />
          {type === 'password' && (
            <Box css={{ mr: '$4', cursor: 'pointer' }} onClick={() => setRevealPassword(reveal => !reveal)}>
              <StyledIcon
                name={revealPassword ? 'visibility' : 'visibility_off'}
                type="outlined"
                pointer={true}
                isDisabled={!!disabled}
                isWarning={!!warningMsg}
                isError={!!errorMsg}
              />
            </Box>
          )}
          {(rightIconName || !!errorMsg || !!warningMsg) && type === 'password' && (
            <Box css={{ mr: '$4', cursor: 'pointer' }}>
              <StyledIcon
                name={errorMsg || warningMsg ? 'error' : rightIconName}
                type="outlined"
                isDisabled={!!disabled}
                isWarning={!!warningMsg}
                isError={!!errorMsg}
              />
            </Box>
          )}
        </SingleLineTextfieldContainer>
      )}
      {(errorMsg || (reactFormErrors && reactFormErrors[name]?.message)) && (
        <MsgWrapper status="error" align="center">
          <ErrorText variant={errorMsgVariant ? errorMsgVariant : 'caption'} type="error" centerText={centerText}>
            {errorMsg || (reactFormErrors ? reactFormErrors[name].message : '')}
          </ErrorText>
        </MsgWrapper>
      )}
      {warningMsg && (
        <MsgWrapper status="warning" align="center">
          <ErrorText variant="caption" type="warning" centerText={centerText}>
            {warningMsg}
          </ErrorText>
        </MsgWrapper>
      )}
      {caption && !errorMsg && (
        <CaptionText variant={captionVariant ? captionVariant : 'caption'} isDisabled={disabled}>
          {caption}
        </CaptionText>
      )}
    </TextfieldWrapper>
  )
})

Textfield.displayName = 'Textfield'

const ErrorText = styled(Text, {
  wordBreak: 'break-all',
  variants: {
    type: {
      warning: {
        color: '$warText',
      },
      error: {
        color: '$aleText',
      },
    },
    centerText: {
      true: {
        width: '100%',
        textAlign: 'center',
      },
      false: {},
    },
  },
  defaultVariants: {
    centerText: false,
  },
})

const StyledIcon = styled(Icon, {
  color: '$gs1',
  variants: {
    isDisabled: {
      true: {
        color: '$gs8',
      },
      false: {
        color: '$gs11',
      },
    },
    isError: { true: {}, false: {} },
    isWarning: { true: {}, false: {} },
  },
  compoundVariants: [
    {
      isError: true,
      isDisabled: false,
      css: {
        color: '$ale',
      },
    },
    {
      isWarning: true,
      isDisabled: false,
      css: {
        color: '$war',
      },
    },
  ],
  defaultVariants: {
    isDisabled: DISABLED_DEFAULT,
    isError: false,
    isWarning: false,
  },
})

const Input = styled('input', sharedTypography, {
  all: 'unset',
  width: '100%',
  height: '100%',
  px: '$4',
  py: '$3',
  boxSizing: 'border-box',
  color: '$gs12',
  '&::placeholder': {
    color: '$gs11',
  },
  '&::focus': {
    border: 'none',
  },
  variants: {
    disabled: {
      true: {
        color: '$gs8',
        'pointer-events': 'none',
        '&::placeholder': {
          color: '$gs8',
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    disabled: false,
  },
})

const SingleLineTextfieldContainer = styled(Flex, {
  height: '$12',
  borderTopLeftRadius: '$3',
  borderTopRightRadius: '$3',
  backgroundColor: '$gs3',
  variants: {
    centerText: {
      true: {
        textAlign: 'center',
      },
      false: {},
    },
    bottomRadius: {
      curved: {
        borderBottomLeftRadius: '$3',
        borderBottomRightRadius: '$3',
      },
      straight: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
    },
    borderColor: {
      pronounced: {
        borderBottom: '1px solid $gs6',
      },
      slight: {
        borderBottom: '1px solid $gs4',
      },
    },
  },
  defaultVariants: {
    bottomRadius: 'curved',
    borderColor: 'pronounced',
  },
})

const TextArea = styled('textarea', sharedTypography, {
  all: 'unset',
  width: '100%',
  borderRadius: '$3',
  wordBreak: 'break-word',
  px: '$4',
  py: '$2',
  boxSizing: 'border-box',
  color: '$gs12',
  backgroundColor: '$gs3',
  resize: 'vertical',
  '&::placeholder': {
    color: '$gs11',
  },
  '&::focus': {
    border: 'none',
  },

  variants: {
    disabled: {
      true: {
        color: '$gs8',
        'pointer-events': 'none',
        '&::placeholder': {
          color: '$gs8',
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    disabled: false,
  },
})

const FULL_WIDTH_DEFAULT = false

const TextfieldWrapper = styled(Flex, {
  variants: {
    isFullWidth: {
      true: {
        width: '100%',
      },
      false: {
        width: '355px',
      },
    },
  },
  defaultVariants: {
    isFullWidth: FULL_WIDTH_DEFAULT,
  },
})

const FloatingLabelText = styled(Text, {
  paddingBottom: '$1',
  variants: {
    isDisabled: {
      true: {
        color: '$gs8',
      },
      false: {
        color: '$gs11',
      },
    },
  },
  defaultVariants: {
    isDisabled: DISABLED_DEFAULT,
  },
})

const CharacterCountText = styled(Text, {
  variants: {
    isOverLimit: {
      true: {
        color: '$ale',
      },
      false: {
        color: '$gs11',
      },
    },
  },
  defaultVariants: {
    isOverLimit: false,
  },
})

const CaptionText = styled(Text, {
  mt: '$2',
  wordBreak: 'break-all',
  variants: {
    isDisabled: {
      true: {
        color: '$gs8',
      },
      false: {
        color: '$gs11',
      },
    },
  },
  defaultVariants: {
    isDisabled: DISABLED_DEFAULT,
  },
})

const MsgWrapper = styled(Flex, {
  px: '$4',
  py: '$2',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  variants: {
    status: {
      error: {
        backgroundColor: '$ale',
      },
      warning: {
        backgroundColor: '$war',
      },
    },
  },
  defaultVariants: {
    status: 'error',
  },
})
